import React from "react";
import home from "../assets/home.png";
import style from "../components/style.css";
// import { useGLTF } from '@react-three/drei'
import { Suspense, useRef } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Canvas, useFrame } from "@react-three/fiber";
import { Circle, Float, OrbitControls, useGLTF } from "@react-three/drei";
import abhimitra from "../assets/abhimitra.jpeg"
import sandeep from "../assets/sandeep.jpeg"
import chira from "../assets/chira.jpeg"
import sidd from "../assets/sidd.jpeg"
import souvik from "../assets/souvik.jpeg"
import subram from "../assets/subram.jpeg"
import sambhav from "../assets/sambhav.jpeg"

// export function Model({ ...props }) {
//   const group = useRef()
//   const { nodes, materials } = useGLTF('/homechar.glb')
//   return (
//     <group ref={group} {...props} dispose={null}>
//       <primitive object={nodes.Hips} />
//       <skinnedMesh geometry={nodes.Wolf3D_Body.geometry} material={materials.Wolf3D_Body} skeleton={nodes.Wolf3D_Body.skeleton} />
//       <skinnedMesh geometry={nodes.Wolf3D_Glasses.geometry} material={materials.Wolf3D_Glasses} skeleton={nodes.Wolf3D_Glasses.skeleton} />
//       <skinnedMesh geometry={nodes.Wolf3D_Hair.geometry} material={materials.Wolf3D_Hair} skeleton={nodes.Wolf3D_Hair.skeleton} />
//       <skinnedMesh geometry={nodes.Wolf3D_Outfit_Bottom.geometry} material={materials.Wolf3D_Outfit_Bottom} skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton} />
//       <skinnedMesh geometry={nodes.Wolf3D_Outfit_Footwear.geometry} material={materials.Wolf3D_Outfit_Footwear} skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton} />
//       <skinnedMesh geometry={nodes.Wolf3D_Outfit_Top.geometry} material={materials.Wolf3D_Outfit_Top} skeleton={nodes.Wolf3D_Outfit_Top.skeleton} />
//       <skinnedMesh name="EyeLeft" geometry={nodes.EyeLeft.geometry} material={materials.Wolf3D_Eye} skeleton={nodes.EyeLeft.skeleton} morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary} morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences} />
//       <skinnedMesh name="EyeRight" geometry={nodes.EyeRight.geometry} material={materials.Wolf3D_Eye} skeleton={nodes.EyeRight.skeleton} morphTargetDictionary={nodes.EyeRight.morphTargetDictionary} morphTargetInfluences={nodes.EyeRight.morphTargetInfluences} />
//       <skinnedMesh name="Wolf3D_Head" geometry={nodes.Wolf3D_Head.geometry} material={materials.Wolf3D_Skin} skeleton={nodes.Wolf3D_Head.skeleton} morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary} morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences} />
//       <skinnedMesh name="Wolf3D_Teeth" geometry={nodes.Wolf3D_Teeth.geometry} material={materials.Wolf3D_Teeth} skeleton={nodes.Wolf3D_Teeth.skeleton} morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary} morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences} />
//     </group>
//   )
// }

export function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/newchar.glb");
  return (
    <group ref={group} {...props} dispose={null}>
      <primitive object={nodes.Hips} />
      <skinnedMesh
        geometry={nodes.Wolf3D_Body.geometry}
        material={materials.Wolf3D_Body}
        skeleton={nodes.Wolf3D_Body.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Glasses.geometry}
        material={materials.Wolf3D_Glasses}
        skeleton={nodes.Wolf3D_Glasses.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Headwear.geometry}
        material={materials.Wolf3D_Headwear}
        skeleton={nodes.Wolf3D_Headwear.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
        material={materials.Wolf3D_Outfit_Bottom}
        skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
        material={materials.Wolf3D_Outfit_Footwear}
        skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Top.geometry}
        material={materials.Wolf3D_Outfit_Top}
        skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
      />
      <skinnedMesh
        name="EyeLeft"
        geometry={nodes.EyeLeft.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeLeft.skeleton}
        morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
      />
      <skinnedMesh
        name="EyeRight"
        geometry={nodes.EyeRight.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeRight.skeleton}
        morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Head"
        geometry={nodes.Wolf3D_Head.geometry}
        material={materials.Wolf3D_Skin}
        skeleton={nodes.Wolf3D_Head.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Teeth"
        geometry={nodes.Wolf3D_Teeth.geometry}
        material={materials.Wolf3D_Teeth}
        skeleton={nodes.Wolf3D_Teeth.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
      />
    </group>
  );
}

export default function Home() {
  return (
    <>
      <div className="home">
        {/* <video src="https://exploitus.chinmayakumarbiswal.in/image/back.mkv" muted loop autoplay type="mp4"></video> */}
        {/* <video loop autoPlay>
       <source src={require('https://exploitus.chinmayakumarbiswal.in/image/back.mkv')} type="video/mp4" />
      </video> */}
        <div className="container">
          <div className="content">
            <p className="sub-title"> Soon</p>
            <h1 className="title">An Ed-tech Metaverse like no other</h1>
            <p className="description">
              We want to put college inside prallel world to change the
              tradition education system and to provide them incredable platform
            </p>
            <button>Sign Up</button>
          </div>

          <div className="image-container">
            <div className="image">
              {/* <img src={home} alt="home image" /> */}
              <Canvas camera={{ fov: 70, position: [0, 0, 5] }}>
                {/* this  above line is the for the objct contraol  */}
                <Suspense fallback={null}>
                  <ambientLight />
                  <directionalLight intensity={2} position={[0, 0, 2]} />
                  <Model />
                  <OrbitControls
                    enablePan={true}
                    enableZoom={true}
                    enableRotate={true}
                  />
                </Suspense>
              </Canvas>
            </div>
            <div className="ellipse-container">
              <div className="ellipse pink"></div>
              <div className="ellipse orange"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="mainabout">
        <div className="about" id="about">
          <span className="abouttext">About</span>
          <span className="whoweare"> Who We Are</span>
          <div className="heroabout">
            <p>
              We are a group of students and enthusiastic amateur blockchain
              application, developers. We are trying to provide the latest and
              cutting edge blockchain solutions to the daily life problem. We
              are also trying to spread awareness of the power and impact of
              blockchain on our present and future. We are one those who believe
              in the idea of freedom, sovereignty, privacy, and trust. We hope
              for a globe without boundaries. centralized authority, censorship.
              corruption. We believe that our dreams problems, and hopes can be
              fulfilled by the application and understanding of blockchain.
            </p>
          </div>
        </div>
      </div>
      <div className="mainteam">
        <span className="teamheading">Team</span>
        <div className="teamsec">
          <div className="teaminfo">
            <img src={sidd} alt="" className="teamimg"  />

            <span className="team">Siddharth Kumar</span>
            <span className="deg">Founder and CEO</span>
          </div>
          <div className="teaminfo">
            <img src={chira} alt="" className="teamimg" />
            <span className="team">Chira Rajeshwari</span>
            <span className="deg">CTO</span>
          </div>
          <div className="teaminfo">
            <img src={subram} alt="" className="teamimg"  />

            <span className="team">Suvram Das</span>
            <span className="deg">CMO</span>
          </div>
         
          <div className="teaminfo">
            <img src={souvik} alt="" className="teamimg"  />

            <span className="team">Souvik Halderv</span>
            <span className="deg">Full-Stack Developer</span>
          </div>
          <div className="teaminfo">
            <img src={sambhav} alt="" className="teamimg"  />

            <span className="team">Sambhav Barik</span>
            <span className="deg">Unity Developer</span>
          </div>
          <div className="teaminfo">
            <img src={sandeep} alt="" className="teamimg"  />

            <span className="team">Sandeep Kumar</span>
            <span className="deg">Mentor</span>
          </div>
          <div className="teaminfo">
            <img src={abhimitra} alt="" className="teamimg" height={120} />

            <span className="team">Abhi Mitra</span>
            <span className="deg">Mentor</span>
          </div>
        </div>
      </div>
    </>
  );
}
